.ui-body-label() {
	line-height: @formInputControlLabelHeight;
	font-size: @fontSizeS;
	font-weight: 600;
}

.font-highlight() {
	font-size: @fontSizeStandard;
	font-weight: 600 !important;
}

.font-tabular() {
	font-size: @fontSizeStandard;
	line-height: @lineHeightS;
}

// Helpers

.font-ellipsis() {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: normal;
}
