@import "../../../less/_variables/general.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/borders.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_mixins/general.less";

@titleWrapperHeightPalm: 57px;
@titleWrapperHeightLapAndUp: 112px;
@titleWrapperBottomPaddingLapAndUp: 18px;
@titleWrapperBottomPaddingPalm: 18px;
@distanceFromTopAndBottom: 2 * 40px;

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes moveInFromBottom {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

.modal-backdrop {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;

	&:before {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
	}

	@media @mediaQueryPalmOnly {
		bottom: 0;
	}

	@media @mediaQueryLapAndUp {
		top: 0;
	}

	&--with-animation {
		&::before {
			animation: fadeIn;
			animation-iteration-count: 1;
			animation-duration: 0.4s;
			animation-fill-mode: forwards;
		}
	}

	&--full-height {
		@media @mediaQueryLapAndUp {
			padding: 30px 0;
			overflow: auto;
		}

		.modal-container {
			transform: unset;
			top: 0;
		}

		.modal-content-wrapper {
			max-height: unset;
		}
	}
}

.modal-container {
	position: relative;
	background-color: var(--color-background-default);
	color: var(--color-text-default);

	@media @mediaQueryPalmOnly {
		position: absolute;
		bottom: 0;
		border-radius: 16px 16px 0 0;
		width: inherit !important; // Overwrite whatever value a user has set
		max-height: 100vh;
		max-height: 100dvh;
		display: flex;
		flex-direction: column;
	}

	@media @mediaQueryLapAndUp {
		border-radius: @lapBorderRadius;
		margin: auto;
		vertical-align: middle;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	&--with-animation {
		@media @mediaQueryPalmOnly {
			animation: moveInFromBottom 0.3s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		@media @mediaQueryLapAndUp {
			animation: fadeIn 0.3s;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}
}

.modal-no-title-wrapper {
	padding-top: 25px;
}

.modal-title-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-height: @titleWrapperHeightLapAndUp;
	padding: @palmSpacingL @palmSpacingL @titleWrapperBottomPaddingPalm @palmSpacingL;
	text-align: left;
	border-bottom: 1px solid var(--color-border-weak);
	overflow: hidden;

	@media @mediaQueryLapAndUp {
		padding: @lapAndUpSpacingM @lapAndUpSpacingL @titleWrapperBottomPaddingLapAndUp @lapAndUpSpacingL;
	}
}

.modal-close-button {
	display: flex;
	min-height: 30px;
	align-items: center;
	font-size: 24px;
	padding: 0 12px;
	color: var(--color-text-default);
	margin-left: auto;

	.remove-button-style();
}

.modal-headline {
	font-weight: 700;
	margin-bottom: 0;
	min-height: 30px;
}

.modal-content-wrapper {
	overflow: auto;

	@media @mediaQueryPalmOnly {
		max-height: calc(100vh - @titleWrapperHeightPalm);
	}

	@media @mediaQueryLapAndUp {
		max-height: calc(100vh - @distanceFromTopAndBottom - @titleWrapperHeightLapAndUp);
	}

	.modal-footer {
		border-top: none;
	}
}

.modal-content {
	display: block;
	padding: @palmSpacingL;

	@media @mediaQueryLapAndUp {
		padding: @lapAndUpSpacingL;
	}
}

.modal-footer {
	gap: 16px;
	border-top: 1px solid var(--color-border-weak);

	@media @mediaQueryLapAndUp {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0, auto));
		justify-content: end;
		padding: @lapAndUpSpacingL;
	}

	@media @mediaQueryPalmOnly {
		display: flex;
		flex-direction: column-reverse;
		padding: @palmSpacingL;
	}
}
