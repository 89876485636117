@import "../../../less/_variables/color.less";
@import "../../../less/_variables/typography.less";

.autocomplete {
	position: relative;

	&-menu {
		width: 100%;
		position: absolute;
		font-family: @fontFamilyStandard-theme-cosma;
		&-open {
			border: 1px solid var(--color-border-input-default);
			background-color: var(--color-background-default);
			color: var(--color-text-default);
			border-radius: 0 0 4px 4px;
			box-shadow: 0 4px 16px 0 #0000001a;
			padding: 4px 0;
		}
	}

	&-category {
		padding: 8px 12px 0;
		color: var(--color-text-weak);
		border-top: 1px solid var(--color-border-weak);
		margin-top: 10px;

		&:first-child {
			margin-top: 0;
			border-top: none;
		}
	}

	&-item {
		padding: 8px 12px;
		cursor: pointer;

		&-highlighted {
			background-color: var(--color-background-weak);
		}
	}

	&-input-open {
		border-radius: 4px 4px 0 0 !important; // Override Input's border-radius
	}

	&-clear {
		width: 16px !important;
		height: 16px !important;
		top: 0 !important;
	}

	&-loading {
		padding: 8px 12px;
		color: var(--color-text-weak);
		display: flex;
		gap: 8px;
		align-items: center;
	}
}
