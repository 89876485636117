@import "../Input.less";
@import "../Labels.less";

.textarea-container > .textarea {
	font-size: @fontSizeStandard;
	line-height: normal;
	resize: vertical;
	overflow: auto;

	.error {
		color: red;
	}
}
