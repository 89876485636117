@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_mixins/general.less";

.dropdown {
	display: inline-block;
	position: relative;
	font-family: @fontFamilyStandard-theme-cosma;

	&-list {
		display: none;
		padding: @lapAndUpSpacingM 0;
		position: absolute;
		left: 0;
		top: calc(100% + 12px);
		border: solid 1px var(--color-border-weak);
		background: var(--color-background-default);
		color: var(--color-text-default);
		box-shadow: 0 2px 4px 0 #00000026;
		border-radius: 8px;
		z-index: 10;
	}

	&-right {
		left: auto;
		right: 0;
	}

	&-item {
		height: 56px;
		padding-left: @lapAndUpSpacingXL;
		padding-right: @lapAndUpSpacingXXL;
		font-size: @fontSizeS;
		line-height: @lineHeightForFontSizeStandard;
		display: flex;
		align-items: center;
		gap: @lapAndUpSpacingM;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover:not(&-disabled),
		&:focus-visible:not(&-disabled),
		&:active:not(&-disabled) {
			background: var(--color-background-weak);
		}

		&-disabled {
			pointer-events: none;
			color: var(--color-text-button-disabled);
		}

		&-danger {
			color: var(--color-text-danger);
		}

		&-multiselect {
			padding-left: 8px;
			padding-right: 8px;

			input {
				cursor: pointer;
			}

			label {
				cursor: pointer;
			}
		}
	}

	&-icon {
		font-size: 24px;
	}

	&-open {
		.dropdown-list {
			display: block;
		}
	}

	&-condensed {
		.dropdown-item {
			height: 40px;
		}
	}

	&-confirm {
		&-item {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 8px;
			padding-top: 8px;
			padding-right: 8px;
		}
	}
}
