@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";

.caption {
	padding: 4px 0;
	font-size: @fontSizeXS;
	display: flex;
	line-height: 16px;
	font-weight: 400;
	font-family: @fontFamilyStandard-theme-cosma;
	color: var(--color-text-default);
	&--info {
		color: var(--color-text-button-link-blue);
	}

	&--success {
		color: var(--color-text-success);
	}

	&--error {
		color: var(--color-text-danger);
	}

	&-icon {
		width: 16px;
		height: 16px;
		margin-right: 4px;
		display: block;
		font-size: 16px;
		flex-shrink: 0;
	}
}
