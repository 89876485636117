@import "../../../less/_variables/color-palette.less";

.skeleton {
	display: inline-block;
	background-color: var(--color-background-weakest);
	height: auto;

	&.rect {
		border-radius: 0;
	}

	&.rounded {
		// TODO: Update to use border-radius token when it becomes available
		border-radius: 8px;
	}

	&.circle {
		border-radius: 50%;
		width: auto;
	}

	&.text {
		// TODO: Update to use border-radius token when it becomes available
		border-radius: 4px;
		height: auto;

		&:empty::before {
			content: " ";
		}
	}

	&.animate {
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
		background-size: 400% 100%;
		animation: skeletonAnimation 1.4s ease infinite;
	}
}

@keyframes skeletonAnimation {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}
