
.popover {
	@parent: popover;
	position: relative;
	z-index: 1;
	padding: 16px;
	display: none;
	background: var(--color-elevation-surface-default);
	box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	transform-style: preserve-3d;

	&::after,
	&::before {
		content: "";
		position: absolute;

		border: 5px solid;
		transform-origin: 0 0;
		transform: rotate(-45deg);
	}

	&-visible {
		display: inline-block;
	}

	&-top {
		&::after,
		&::before {
			border-color: transparent transparent var(--color-elevation-surface-default) var(--color-elevation-surface-default);
			bottom: -9px;
		}
		&::before {
			box-shadow: -1px 1px 7px 0 rgba(0, 0, 0, 0.1);
			transform: rotate(-45deg) translateZ(-1px);
		}
	}

	&-bottom {
		&::after,
		&::before {
			border-color: var(--color-elevation-surface-default) var(--color-elevation-surface-default) transparent transparent;
			top: 1px;
		}
		&::before {
			box-shadow: 1px -1px 7px 0 rgba(0, 0, 0, 0.1);
			transform: rotate(-45deg) translateZ(-1px);
		}
	}

	&-left {
		&::after,
		&::before {
			border-color: transparent var(--color-elevation-surface-default) var(--color-elevation-surface-default) transparent;
			right: -2px;
		}
		&::before {
			box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
			transform: rotate(-45deg) translateZ(-1px);
		}
	}

	&-right {
		&::after,
		&::before {
			border-color: var(--color-elevation-surface-default) transparent transparent var(--color-elevation-surface-default);
			left: -6px;
		}
		&::before {
			box-shadow: -1px -1px 7px 0 rgba(0, 0, 0, 0.1);
			transform: rotate(-45deg) translateZ(-1px);
		}
	}

	&-top&-arrow-left,
	&-bottom&-arrow-left {
		&::after,
		&::before {
			left: 17px;
		}
	}

	&-top&-arrow-center,
	&-bottom&-arrow-center {
		&::after,
		&::before {
			left: calc(50% - 7px);
		}
	}

	&-top&-arrow-right,
	&-bottom&-arrow-right {
		&::after,
		&::before {
			right: 20px;
		}
	}

	&-left&-arrow-right,
	&-right&-arrow-left {
		&::after,
		&::before {
			bottom: 13px;
		}
	}

	&-left&-arrow-center,
	&-right&-arrow-center {
		&::after,
		&::before {
			bottom: calc(50% - 10px);
		}
	}

	&-left&-arrow-left,
	&-right&-arrow-right {
		&::after,
		&::before {
			top: 22px;
		}
	}
}
