@import "../../../less/_variables/forms.less";
@import "../../../less/_mixins/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";

// labels
.input-label {
	padding-bottom: @formInputLabelPaddingBottom;
	&.mandatory {
		&::after {
			content: "*";
			font-weight: normal;
			color: var(--color-text-default);
			margin-left: 4px;
		}
	}
}

.input-label,
.label-radio,
.label-checkbox,
.label-toggle-switch,
.form label {
	display: block;
	color: var(--color-text-default);
	.ui-body-label();
}

.label-optional::after {
	content: " (opt.)";
	font-weight: 400;
	color: var(--color-text-weak);
}

// helper text labels
.input-label-helper-text {
	display: inline-block;
	font-size: @fontSizeS;
	color: var(--color-text-weak);
	margin-top: @formInputLabelHelperTextVertical;

	.gutter-form & {
		margin-bottom: -@spacingFormVertical;
	}

	&:empty {
		display: inline !important;
	}
}
