@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/color-palette.less";

.real-estate-card {
	width: 263px;
	background-color: var(--color-background-default);
	border: 1px solid var(--color-border-default);
	border-radius: 16px; // Integrate with CSS variables in Cosma v7
	position: relative;
	overflow: hidden;
	font-family: @fontFamilyStandard-theme-cosma;

	&-brandbar {
		width: 100%;
		height: 32px;
		position: absolute;
		top: 0;
		left: 0;

		&-content {
			width: 100%;
			padding-right: 16px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			position: absolute;
			top: 100%;
			left: 0;
			transform: translateY(-50%);
		}

		&-brand {
			margin-right: -2px;
			display: flex;
			position: relative;
			box-shadow: 0px 4px 10px 2px #00000014;
			border-radius: 4px;

			&:not(:only-child)::after {
				content: "";
				position: absolute;
				margin-left: -4px;
				top: 0;
				left: 100%;
				width: 8px;
				height: 100%;
				background-color: @gray100;
			}
		}

		&-logo {
			height: 28px;
			border: solid 2px @gray100;
			border-radius: 4px;
			z-index: 1;
		}

		&-avatar {
			width: 48px;
			height: 48px;
			border: solid 2px @gray100;
			border-radius: 50%;
			object-fit: cover;
			z-index: 1;
		}
	}

	&-image {
		width: 100%;
		height: 197px;
		display: block;
		object-fit: cover;
	}

	&-body {
		padding: 16px;
	}

	&-container {
		display: flex;
		gap: 8px;
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 8px;
		overflow: hidden;
	}

	&-title {
		height: 44px; // 2x line-height
		color: var(--color-text-default);
		font-size: 14px;
		line-height: 22px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&-criteria {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		gap: 16px;
		color: var(--color-text-default);

		& > * {
			margin: 0;
			white-space: nowrap;
		}

		& > *:last-child {
			text-wrap: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-button {
		color: var(--color-text-default);
		padding: 0;
		height: 24px;

		&:hover,
		&:focus {
			color: var(--color-text-default);
		}
	}

	&-address {
		margin-top: 8px;
		display: flex;
		align-items: center;
		color: var(--color-text-weak);
		&-content {
			margin-left: 4px;
			flex: 1;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&-pin {
		width: 16px;
		height: 16px;
	}
}
