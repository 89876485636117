@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";
@import "../../../less/helpers.less";

// font style
.font-italic {
	font-style: italic !important;
}

.font-strike {
	text-decoration: line-through !important;
}

// text transform
.font-uppercase {
	text-transform: uppercase !important;
}

.font-capitalized {
	text-transform: capitalize !important;
}

// wrapping
.font-nowrap {
	white-space: nowrap !important;
}

// break words
.font-break-all {
	word-break: break-all;
}

.font-ellipsis {
	.font-ellipsis();
}

.font-highlight {
	.font-highlight();
}

.font-tabular {
	.font-tabular();
}

.font-hint {
	font-size: @fontSizeXS;
}

// alignment
.font-center {
	text-align: center !important;
}

.font-left {
	text-align: left !important;
}

.font-right {
	text-align: right !important;
}
