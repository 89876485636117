// --- Reset ---

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
label,
input,
select,
textarea,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
footer,
header,
main,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	.box-sizing(border-box);
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
}
article,
aside,
details,
figure,
figcaption,
footer,
header,
main,
menu,
nav,
section {
	display: block; // HTML5 block elements for legacy browsers
}
ul {
	list-style-type: none;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}
th,
td {
	vertical-align: top;
	text-align: left; // a better option would be "start" for rtl compatibility, but it lacks support
	font-weight: normal;
}
input,
select,
textarea,
button {
	margin: 0;
	font-size: 1em; // overwrites potential user agent styles which mess with the standard font size declared on the body element
}
