@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";
@import "../Button/Button.less";
@import "../../../less/_mixins/general.less";

.datepicker-nav {
	&--top-offset {
		margin-top: @lapAndUpSpacingStandard;
	}
}

button.datepicker-close-btn {
	position: absolute;
	top: 16px;
	right: 2px;

	@media @mediaQueryLapAndUp {
		top: 16px;
		right: 12px;
	}
}

.datepicker-subheadline {
	font-size: 14px;
	line-height: 20px;
	padding-top: 6px;
	color: var(--color-text-default);
	font-weight: 400;
}

.datepicker-previous-month-button,
.datepicker-next-month-button {
	height: 30px;
	font-size: 24px;
	color: var(--color-text-default);
	.remove-button-style();
}

.datepicker-previous-month-button {
	margin-right: auto;
	margin-left: -8px;
}

.datepicker-next-month-button {
	margin-right: -8px;
	margin-left: auto;
}

.datepicker-current-month {
	line-height: 26px;
	font-family: @fontFamilyStandard-theme-cosma;
	font-weight: bold;
	font-size: @fontSizeM;
}

.datepicker-days-wrapper {
	margin-top: 4px;
	margin-bottom: 0;

	margin-right: -@palmSpacingL;
	margin-left: -@palmSpacingL;
	padding-right: 22px;
	padding-left: 22px;
	width: auto;

	@media @mediaQueryLapAndUp {
		margin-right: -@lapAndUpSpacingL;
		margin-left: -@lapAndUpSpacingL;
	}
}

.datepicker-day-cell() {
	width: 100% / 7;
	height: 36px;
	line-height: 36px;
	text-align: center;
	border: none;
	outline: none;
	padding: 0;
	background-color: transparent; // Remove button style for Safari
	color: var(--color-text-default);
	margin-top: 2px;
	font-size: @fontSizeS;
	font-family: @fontFamilyStandard-theme-cosma;

	&.datepicker-day-cell-outside-of-month {
		color: var(--color-text-weak);
	}

	&.datepicker-disabled {
		color: var(--color-text-button-disabled);
		font-weight: 300;
	}

	&.datepicker-start-date,
	&.datepicker-end-date,
	&:focus {
		z-index: 1;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			left: calc(50% - 18px);
			top: 0;
			height: 36px;
			width: 36px;
			z-index: -1;
			border-radius: 100%;
		}
	}

	&.datepicker-start-date,
	&.datepicker-end-date {
		color: var(--color-text-charcoal);
		font-weight: 900;

		&:focus::before,
		&::before {
			background-color: @color-brand-theme-cosma;
		}
	}

	button&:focus {
		&::before {
			background-color: fade(@color-brand-theme-cosma, 16%);
		}
	}

	&.datepicker-in-date-range {
		background-color: fade(@color-brand-theme-cosma, 16%);

		&.datepicker-start-date {
			.color-scheme(background, linear-gradient(90deg, #fff 50%, fade(@color-brand-theme-cosma, 16%) 50%), linear-gradient(90deg, #222222 50%, fade(@color-brand-theme-cosma, 16%) 50%));
		}

		&.datepicker-end-date {
			.color-scheme(background, linear-gradient(90deg, fade(@color-brand-theme-cosma, 16%) 50%, #fff 50%), linear-gradient(90deg, fade(@color-brand-theme-cosma, 16%) 50%, #222222 50%));
		}
	}
}

.datepicker-day-cell:global(.grid-item) {
	.datepicker-day-cell();
}

.datepicker-day-cell.grid-item {
	.datepicker-day-cell();
}

.datepicker-weekdays-in-title {
	margin: 10px 4px 0 4px;
	background-color: var(--color-background-weakest);
	margin-right: -@palmSpacingL;
	margin-left: -@palmSpacingL;
	padding-right: 22px;
	padding-left: 22px;
	width: auto;

	@media @mediaQueryLapAndUp {
		margin-right: -@lapAndUpSpacingL;
		margin-left: -@lapAndUpSpacingL;
	}

	.datepicker-weekday-title-cell.grid-item {
		.datepicker-day-cell();
		margin-top: 0;
		font-weight: 600;
		color: var(--color-text-weak);
		height: 33px;
		line-height: 33px;
		padding: 0;
	}

	.datepicker-weekday-title-cell:global(.grid-item) {
		.datepicker-day-cell();
		margin-top: 0;
		font-weight: 600;
		height: 33px;
		line-height: 33px;
		padding: 0;
		color: var(--color-text-weak);
	}
}

.datepicker-okay-button {
	margin-left: 16px;
}

.datepicker-okay-button,
.datepicker-cancel-button {
	margin-top: 16px;
	width: ~"calc(50% - 8px)";
	min-width: ~"calc(50% - 8px)";
	padding: @buttonPaddingVerticalSmall @buttonPaddingVerticalSmall;
}

.datepicker-cancel-button {
	color: var(--color-text-default);
	border-color: var(--color-border-button-strong-default)
}
