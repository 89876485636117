@import "../Input.less";
@import "../Labels.less";

.stepper-input-container {
	display: flex;

	.input-text {
		z-index: 1;
		text-align: center;
		border-radius: 0;
		width: calc(100% - 2*@StepperButtonWidth);
	}
}

.stepper-increment-button,
.stepper-decrement-button {
	// Just setting width did not result in our desired width of 40px
	min-width: @StepperButtonWidth;
	max-width: @StepperButtonWidth;
	border: @formElementBorderThickness solid;
	transition: border @transitionTimeStandard;
	background-color: inherit;
	color: var(--color-text-default);
	border-color: var(--color-border-input-default);


	&:hover {
		z-index: 2;
		border-color: var(--color-border-input-hover);
	}
	&:focus {
		z-index: 2;
		border-color: var(--color-border-input-focused);
	}
}

.stepper-increment-button {
	margin-left: -1px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.stepper-decrement-button {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	margin-right: -1px;
}
