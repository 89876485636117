@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";

// TODO replace all values with tokens
.segment-control {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: none;
	background-color: var(--color-background-weak);
	border-radius: 999px;
	padding: 4px;
	gap: 8px;

	&.segment-control-size-large {
		padding: 8px;
		gap: 8px;
	}
}
