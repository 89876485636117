@import "../../../less/_variables/forms.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/borders.less";
@import "../../../less/_mixins/general.less";

.input-text-container,
.select-container,
.textarea-container {
	position: relative;

	> .affix-left,
	> .affix-right,
	> .input-icon-left,
	> .input-icon-right {
		position: absolute;
		margin-left: @formElementInnerHorizontalMargin;
		margin-right: @formElementInnerHorizontalMargin;
		color: var(--color-text-default);
		font-size: @formElementIconSize;
		line-height: @formElementHeight;
		// Turn icon clicks into clicks on the underlying field
		pointer-events: none;
		z-index: 1;

		+ .select {
			padding-left: @formElementTextWithIconMargin;
		}
	}

	> .affix-left,
	> .input-icon-left {
		~ .input-text {
			padding-left: @formElementTextWithIconMargin;
		}
	}

	> .affix-right,
	> .input-icon-right {
		right: 0;

		~ .input-text {
			padding-right: @formElementTextWithIconMargin;
		}
	}

	> .affix-unit {
		text-align: center;
		font-size: @formElementUnitSize;
		width: @formElementTextWithIconMargin;
		margin-left: auto;
		margin-right: auto;
	}
}

// inputs
.input-text,
.select,
.textarea {
	// Reset the appearance for all form elements. Desktop browsers only need this for select fields but iOS needs it for text fields, too.
	border-radius: 4px;
	border: @formElementBorderThickness solid var(--color-border-input-default);
	transition: border @transitionTimeStandard;
	padding: 0 @formElementInnerHorizontalMargin;
	background-color: var(--color-background-default);
	width: 100%;
	caret-color: var(--color-text-default);
	color: var(--color-text-default);
	font-size: 1.6rem;
	font-family: @fontFamilyStandard-theme-cosma;

	.select-container > & {
		// Appearance is not supported by Internet Explorer. We have made special arrangements for Internet Explorer for this.
		/* doiuse-disable css-appearance */
		-webkit-appearance: none;
		appearance: none;
		/* doiuse-enable css-appearance */
		@media @mediaQueryIE11Only {
			padding-right: 0;

			&::after {
				display: none;
			}
		}
	}

	&:hover {
		border-color: var(--color-border-input-hover);
	}
	&:focus {
		border-color: var(--color-border-input-focused);
	}

	&::placeholder {
		color: var(--color-text-weak);
	}

	&:disabled {
		color: var(--color-text-button-disabled);
		border-color: var(--color-border-button-disabled);
		background-color: var(--color-background-weak);
		&::placeholder,
		~ .input-label-helper-text {
			color: var(--color-text-button-disabled);
		}
	}
	~ .input-label-helper-text-error {
		color: var(--color-text-danger);
	}
}

// regular input size is handled through the height property so that they can scale consistently
.input-text,
.select {
	height: @formElementHeight;
	// Set some attributes explicitly in case someone wants to use these classes with <div>s instead of the usual <input>s or <select>s through which we would get these implicitly.
	&:not(input):not(select) {
		line-height: @formElementHeight;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

// textarea elements need top and bottom paddings for layout
.textarea {
	padding-top: @formElementInnerVerticalMargin;
	padding-bottom: @formElementInnerVerticalMargin;
}

.input-text.error,
.select.error,
.textarea.error {
	border-color: var(--color-border-danger);
}

.input-text.success,
.textarea.success {
	border-color: var(--color-border-success);
}

.selection-control(@type) {
	.@{type}-container {
		position: relative;
		border: @borderWidthStandard solid transparent;
		border-bottom: none;

		.input-@{type} {
			// Appearance is not supported by Internet Explorer. We have checked Internet Explorer and it behaves the way we expect it to.
			/* doiuse-disable css-appearance */
			-webkit-appearance: none;
			appearance: none;
			/* doiuse-enable css-appearance */
			position: absolute;
			height: @formInputControlFocusElementSize;
			transition: box-shadow @transitionTimeStandard;

			&:disabled,
			&:checked:disabled {
				+ .label-@{type} {
					color: var(--color-text-button-disabled);
				}
			}
		}

		.label-@{type} {
			font-weight: 400;
			padding-top: @formInputControlPaddingVertical;
			padding-bottom: @formInputControlPaddingVertical;
		}
	}
}

.selection-control-list(@type) {
	.@{type}-list {
		.@{type}-container {
			border-color: var(--color-border-weak);

			&:first-child {
				border-top-left-radius: @borderRadiusStandard;
				border-top-right-radius: @borderRadiusStandard;
			}

			&:last-child {
				border-bottom: @borderWidthStandard solid var(--color-border-weak);
				border-bottom-left-radius: @borderRadiusStandard;
				border-bottom-right-radius: @borderRadiusStandard;

				.label-@{type} {
					padding-bottom: @formInputControlPaddingVertical - @borderWidthStandard;
				}
			}
		}
	}
}

.selection-control-list(radio);
.selection-control-list(checkbox);
.selection-control-list(toggle-switch);

.selection-control-with-label-on-right(@type) {
	.@{type}-container {
		transition: background-color @transitionTimeStandard;

		.input-@{type} {
			width: @formInputControlFocusElementSize;
			border-radius: 100%;
		}

		.label-@{type} {
			// Reset the styles that come in from the now deprecated checkbox style
			margin-left: 0;
			padding-right: @formInputControlPaddingHorizontal;
			padding-left: @formInputControlPaddingHorizontal + @formInputCheckboxSize + @formInputControlMarginBetweenControlAndLabel;

			&::before {
				transition: background-color @transitionTimeStandard;
				margin-left: -(@formInputControlMarginBetweenControlAndLabel + @formInputCheckboxSize);
				position: absolute;
				content: "";
				border: @borderWidthStandard solid var(--color-border-input-default);
				background-color: var(--color-background-default);
			}

			&.error::before {
				border-color: var(--color-border-danger);
			}
		}

		&:hover {
			background-color: initial;
		}
	}
}

.clickable-icon {
	pointer-events: visible !important;
	cursor: pointer;
}
