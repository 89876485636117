@import "../../../less/_variables/color.less";
@import "../../../less/_mixins/general.less";

.divider {
	margin: 0;
	flex-shrink: 0;
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: var(--color-border-weak);
}

.divider-large {
	border-width: 0 0 2px;
}

.divider-flex {
	height: auto;
	align-self: stretch;
}
